<template>
  <div>
    <div class="container space-top-2">
      <div class="w-md-85 w-lg-65 mx-auto text-center">
        <h1 class="">{{ $t('UseCases.Overview.Title') }}</h1>
        <p class="lead">{{ $t('UseCases.Overview.Description') }}</p>
      </div>
    </div>
    <div class="position-relative">
      <div class="container space-2">
        <div class="row">
          <div v-for="(category, index) in categories" :key="index" class="col-sm-6 col-lg-3 use-cases-card px-2 mb-3">
            <router-link
              class="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
              :to="{ path: `/${$route.params.lang}/use-cases/${category.id}`}"
              :style="{ backgroundImage: `url(${require(`@/assets/svg/illustrations/use-cases/${category.bgImage}.svg`)})`, backgroundColor: `${category.bgColor}`}"
            >
              <div class="card-body text-shadow">
                <small class="d-block small text-white font-weight-bold text-cap mb-2">{{ $t('UseCases.Overview.Industry') }}</small>
                <h3 class="text-white">{{ $t(category.categoryName) }}</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-100 w-md-60 bg-blue-light position-absolute top-0 right-0 bottom-0 rounded-left z-index-n1" />
    </div>
  </div>
</template>

<script>
import UseCasesCategories from './useCasesCategories.json'

export default {
  name: 'UseCases',
  data() {
    return {
      categories: UseCasesCategories.Categories
    }
  },
  metaInfo() {
    return {
      title: 'API Use Cases',
      titleTemplate: '%s | OpenAPIHub',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/use-cases' }
      ],
      meta: [
        { name: 'description', content: 'Explore our APIs by different industries.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'API Use Cases | OpenAPIHub' },
        { property: 'og:description', content: 'Explore our APIs by different industries.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/use-cases' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.12);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
